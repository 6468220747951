var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.$store.getters.getHaveLicense)?_c('select-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'users',
        'prefix': 'trigger_action_admin_',
        disabled: _vm.disabled,
        'validation': 'required',
        'options': _vm.getAdmins,
        'multiple': true
      }
    }}}):_c('chips-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'users',
        'prefix': 'trigger_action_',
        disabled: _vm.disabled,
        'validation': 'required',
        'options': _vm.$store.state.chatState.chat.received_administrators
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }