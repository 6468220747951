import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import MuteActionSetupView from "@/components/TriggerSetup/actions/MuteAction/MuteActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': MuteActionSetupView,
  'structure': {
    'type': ActionsType.MuteAction,
    'target'      : "Caller",
    'restrictType': null,
    'time'        : 0, // Секунд
    'chance'      : 100,
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.MuteAction) }`
}

export default action
