import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import ReputationEquals from "./conditions/ReputationEquals";
import ReputationConditionsWrapper from "./ReputationConditionsWrapper.vue";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': ReputationConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.ReputationConditionsWrapper,
    'currentConditionType': ReputationEquals.structure.type,
    'currentCondition': ReputationEquals.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.ReputationConditionsWrapper) }`
}

export default condition
