var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('accordion',{staticClass:"extra-settings mx-2",attrs:{"open-title":"advanced_settings_open","close-title":"advanced_settings_close"},model:{value:(_vm.isExtraSettingsOpen),callback:function ($$v) {_vm.isExtraSettingsOpen=$$v},expression:"isExtraSettingsOpen"}},[_c('div',{staticClass:" flex flex-col"},[_c('div',{staticClass:"flex flex-col w-full md:flex-row"},[_c('time-picker-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.triggerData,
            'key': 'from',
            'prefix': 'trigger_setup_',
            'disabled': _vm.disabled,
            'time24hr': true
          }
        }}}),_c('time-picker-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.triggerData,
            'key': 'to',
            'prefix': 'trigger_setup_',
            'disabled': _vm.disabled,
            'time24hr': true
          }
        }}})],1),_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'delay',
          'prefix': 'trigger_setup_',
          'disabled': _vm.disabled,
        }
      }}}),_c('number-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'chance',
          'prefix': 'trigger_setup_',
          'max': 100,
          'disabled': _vm.disabled,
        }
      }}}),(_vm.triggerData.transparent)?_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'transparent',
          'prefix': 'trigger_setup_',
          'disabled': _vm.disabled,
        }
      }}}):_vm._e(),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'processAlbumsLikeFirstPhoto',
          'prefix': 'trigger_setup_',
          'disabled': _vm.disabled,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'processEditedMessages',
          'prefix': 'trigger_setup_',
          'disabled': _vm.disabled,
        }
      }}}),(_vm.$store.state.chatState.chat.config.triggers_mode === _vm.TriggersMode.Linear)?_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'continueProcessAfterSuccessEdit',
          'prefix': 'trigger_setup_',
          'disabled': _vm.disabled,
        }
      }}}):_vm._e(),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'disableLog',
          'prefix': 'trigger_setup_',
          'tags': !_vm.$store.getters.isChatAtLeastAdvanced ? [{color: _vm.$store.state.themeConfig.config.tariffs.advanced.color, text: 'Advanced'}] : null,
          'disabled': !_vm.$store.getters.isChatAtLeastAdvanced,
        }
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }