






































































































import { IterableListItem, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { TriggerFactory } from "@/components/TriggerSetup/logic/factories/trigger-factory";
import CreateCondition from "@/components/TriggerSetup/components/CreateCondition/CreateCondition.vue";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import ConfigGroup from "piramis-base-components/src/components/ConfigGroup/ConfigGroup.vue";
import BlockMovingControls from "piramis-base-components/src/components/BlockMovingControls/BlockMovingControls.vue";
import { ListDirection } from "piramis-base-components/src/components/Pi/components/List/logic/types";
import List from "piramis-base-components/src/components/Pi/components/List/List.vue";

import Vue from 'vue'
import { snakeCase } from "lodash";
import { Component, Prop, VModel } from 'vue-property-decorator'

@Component({
  'components': {
    CreateCondition,
    ConfigGroup,
    BlockMovingControls,
    List
  },

  'data': () => ({
    ListDirection,
  }),

  'methods': {
    snakeCase
  }
})
export default class ConditionsGroups extends Vue {
  @VModel({ 'type': Array, 'required': true }) model!: Array<IterableListItem<Array<IterableListItem<TriggerCondition>>>>

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop() readonly filteredConditions!: Array<EntityData>

  triggerFactory: TriggerFactory = new TriggerFactory()

  // getSetupView(item: {guid: string, value: Array<{ guid: string, value:TriggerCondition }>}, index: number): any {
  //   let currentCondition: EntityData | null = null
  //   Object.values(this. filteredConditions).forEach(condition => {
  //
  //     if(condition.structure.type === item.value[index].value.structure.type) {
  //       currentCondition = condition
  //     }
  //   })
  //
  //   return currentCondition ? (currentCondition as EntityData).setupView : null
  // }

  removeConditionGroup(index: number): void {
    this.$emit('removeConditionGroup', this.model[index].guid)
    this.model = this.model.filter((filteredItem, filteredIndex) => filteredIndex !== index)
  }

  createCondition(conditionGroup: IterableListItem<Array<IterableListItem<TriggerCondition>>>, structure: EntityData): void {
    conditionGroup.value.push(this.triggerFactory.createCondition(structure))
  }

  addConditionsGroup(conditionsGroups: Array<IterableListItem<Array<IterableListItem<TriggerCondition>>>>): void {
    conditionsGroups.push(this.triggerFactory.createConditionsGroup())
  }
}
