import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { snakeCase } from "lodash";
import { ConditionsWrapperType } from "../../logic/types/types";
import XpUpper from "./conditions/XpUpper";
import XpConditionsWrapper from "./XpConditionsWrapper.vue";

const condition: EntityData = {
  'setupView': XpConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.XpConditionsWrapper,
    'currentConditionType': XpUpper.structure.type,
    'currentCondition': XpUpper.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.XpConditionsWrapper) }`
}

export default condition
