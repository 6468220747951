

























import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import MessageTextRegExpSetupView from './conditions/MessageTextRegExp'
import MessageMentionEntityTypeSetupView from './conditions/MessageMentionEntityType'
import ReplyMessageTextRegExpSetupView from './conditions/ReplyMessageTextRegExp'
import ReplyUserLoginRegExpSetupView from './conditions/ReplyUserLoginRegExp'
import ReplyUserNameRegExpSetupView from './conditions/ReplyUserNameRegExp'
import UserLoginRegExpSetupView from './conditions/UserLoginRegExp'
import UserNameRegExpSetupView from './conditions/UserNameRegExp'
import MessagePhoneNumberEntityTypeSetupView from '../MessagePhoneNumberEntityType'
import MessageTextMentionEntitySetupView from './conditions/MessageTextMentionEntityType'
import { conditionsWrappers } from '@/components/TriggerSetup/conditions'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import { snakeCase } from 'lodash'
import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class RegexpConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView) {

  regexpConditionOptions: Array<SelectOptionData> = []

  deprecatedConditions = [
    'MessageMentionEntityType',
    'MessageTextMentionEntityType',
    'MessagePhoneNumberEntityType'
  ]

  created():void {
    this.regexpConditionOptions = conditionsWrappers.RegexpConditionsWrapper
      .filter(condition => {
        if (this.deprecatedConditions.includes(condition)) {
          return condition === this.conditionData.currentConditionType;
        }

        return true
      })
      .map(type => {
        const option: SelectOptionData = {
          label: this.$t(`trigger_condition_${ snakeCase(type) }`).toString(),
          value: type
        }

        if (this.deprecatedConditions.includes(type)) {
          option.tags = [ { text: this.$t('deprecated_setting_tag_title').toString(), color: 'rgba(var(--a-danger))' } ]

          return option
        }

        return option
      })
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === 'MessageTextRegExp') {
      return MessageTextRegExpSetupView
    } else if (this.conditionData.currentConditionType === 'MessageMentionEntityType') {
      return MessageMentionEntityTypeSetupView
    } else if (this.conditionData.currentConditionType === 'ReplyMessageTextRegExp') {
      return ReplyMessageTextRegExpSetupView
    } else if (this.conditionData.currentConditionType === 'ReplyUserLoginRegExp') {
      return ReplyUserLoginRegExpSetupView
    } else if (this.conditionData.currentConditionType === 'ReplyUserNameRegExp') {
      return ReplyUserNameRegExpSetupView
    } else if (this.conditionData.currentConditionType === 'UserLoginRegExp') {
      return UserLoginRegExpSetupView
    } else if (this.conditionData.currentConditionType === 'UserNameRegExp') {
      return UserNameRegExpSetupView
    } else if (this.conditionData.currentConditionType === 'MessagePhoneNumberEntityType') {
      return MessagePhoneNumberEntityTypeSetupView
    } else if (this.conditionData.currentConditionType === 'MessageTextMentionEntityType') {
      return MessageTextMentionEntitySetupView
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = this.viewByConditionType.structure
      }
    })
  }

}
