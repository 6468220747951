


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { ConditionsType } from '../../logic/types/types'
import ReplyReputationEquals from './conditions/ReplyReputationEquals'
import ReplyReputationUpper from './conditions/ReplyReputationUpper'
import ReplyReputationLower from './conditions/ReplyReputationLower'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component
export default class ReplyReputationConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get replyReputationConditionOptions():Array<SelectOption> {
    return conditionsWrappers.ReplyReputationConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`).toString(),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.ReplyReputationEquals) {
      return ReplyReputationEquals
    } else if (this.conditionData.currentConditionType === ConditionsType.ReplyReputationUpper) {
      return ReplyReputationUpper
    } else if (this.conditionData.currentConditionType === ConditionsType.ReplyReputationLower) {
      return ReplyReputationLower
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = this.viewByConditionType.structure
      }
    })
  }
}
