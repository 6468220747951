


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { ConditionsType } from '../../logic/types/types'
import ActionPointsEquals from './conditions/ActionPointsEquals'
import ActionPointsLower from './conditions/ActionPointsLower'
import ActionPointsUpper from './conditions/ActionPointsUpper'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component
export default class ActionPointsConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get actionPointsConditionOptions():Array<SelectOption> {
    return conditionsWrappers.ActionPointsConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`).toString(),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.ActionPointsEquals) {
      return ActionPointsEquals
    } else if (this.conditionData.currentConditionType === ConditionsType.ActionPointsLower) {
      return ActionPointsLower
    } else if (this.conditionData.currentConditionType === ConditionsType.ActionPointsUpper) {
      return ActionPointsUpper
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = this.viewByConditionType.structure
      }
    })
  }
}
