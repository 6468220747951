


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { ConditionsType } from '@/components/TriggerSetup/logic/types/types'
import ReplyMessageWordFullMatch from './conditions/ReplyMessageWordFullMatch'
import ReplyMessageWordStartWith from './conditions/ReplyMessageWordStartWith'
import ReplyMessageWordEndWith from './conditions/ReplyMessageWordEndWith'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component
export default class ReplyMessageWordConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get replyMessageWordConditionOptions():Array<SelectOption> {
    return conditionsWrappers.ReplyMessageWordConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.ReplyMessageWordFullMatch) {
      return ReplyMessageWordFullMatch
    } else if (this.conditionData.currentConditionType === ConditionsType.ReplyMessageWordStartWith) {
      return ReplyMessageWordStartWith
    } else if (this.conditionData.currentConditionType === ConditionsType.ReplyMessageWordEndWith) {
      return ReplyMessageWordEndWith
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = this.viewByConditionType.structure
      }
    })
  }
}
