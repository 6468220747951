























































































































import { InputSetups } from '../../../mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import { TriggersMode } from '@/includes/types/triggers/Enums'

@Component({
  components: {
    Accordion,
  },
  data() {
    return {
      TriggersMode
    }
  }
})
export default class TriggerExtraSettings extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() triggerData!:any

  @Prop() disabled!:boolean

  isExtraSettingsOpen = false

  get areExtraSettingsEnabled(): boolean {
    return Boolean(this.triggerData.from
      || this.triggerData.to
      || this.triggerData.transparent
      || this.triggerData.chance !== 100
      || this.triggerData.delay)
  }

  mounted():void {
    if (this.areExtraSettingsEnabled) {
      this.isExtraSettingsOpen = true
    }
  }
}
