

























import { ActionSetupView } from '@/components/TriggerSetup/logic/mixins/action-setup-view.mixin'
import { InputSetups } from '@/mixins/input-setups'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types';
import { FileType } from 'piramis-base-components/src/components/File/types';

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  data() {
    return {
      MediaEditorMode,
      FileType
    }
  }
})
export default class ActionText extends Mixins<ActionSetupView, InputSetups>(ActionSetupView, InputSetups) {
  @Prop() titlePrefix!:string

  @Prop() hasTopics!:boolean

  get topics() {
    if (this.hasTopics) {
      return {
        'settingsStructure': this.defaultMessageEditorSettingsStructure,
        'options': {
          topics: this.topicsAvailable
        },
        ...this.topicsIfForum
      }
    }

  }
}
