import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import ActionPointsConditionsWrapper
  from "@/components/TriggerSetup/conditions/ActionPointsConditionsWrapper/ActionPointsConditionsWrapper.vue";
import ActionPointsEquals from "./conditions/ActionPointsEquals";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': ActionPointsConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.ActionPointsConditionsWrapper,
    'currentConditionType': ActionPointsEquals.structure.type,
    'currentCondition': ActionPointsEquals.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.ActionPointsConditionsWrapper) }`
}

export default condition
