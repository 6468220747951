


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { ConditionsType } from '@/components/TriggerSetup/logic/types/types'
import RankEquals from './conditions/RankEquals'
import RankLower from './conditions/RankLower'
import RankUpper from './conditions/RankUpper'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component
export default class RankConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get rankConditionOptions():Array<SelectOption> {
    return conditionsWrappers.RankConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.RankUpper) {
      return RankUpper
    } else if (this.conditionData.currentConditionType === ConditionsType.RankLower) {
      return RankLower
    } else if (this.conditionData.currentConditionType === ConditionsType.RankEquals) {
      return RankEquals
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = this.viewByConditionType.structure
      }
    })
  }
}
