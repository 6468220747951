import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import ReplyActionPointsConditionsWrapper from "./ReplyActionPointsConditionsWrapper.vue";
import ReplyActionPointsEquals from "./conditions/ReplyActionPointsEquals";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': ReplyActionPointsConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.ReplyActionPointsConditionsWrapper,
    'currentConditionType': ReplyActionPointsEquals.structure.type,
    'currentCondition': ReplyActionPointsEquals.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.ReplyActionPointsConditionsWrapper) }`
}

export default condition
