






































































































import { TriggerHelper, TriggerInputsSetups } from '@/components/TriggerSetup/logic/mixins/TriggerSetupMixin'
import { TriggerData } from '@/components/TriggerSetup/logic/types/types'
import ActionsGroups from '@/components/TriggerSetup/components/ActionsGroups/ActionsGroups.vue'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import Overlay from 'piramis-base-components/src/components/Overlay/Overlay.vue'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { cloneDeep } from 'lodash'
import TriggerAdvancedValidateMixin
  from '@/components/TriggerSetup/components/TriggerAdvancedValidate/TriggerAdvancedValidateMixin.vue'

@Component({
  'components': {
    ValidationObserver,
    ActionsGroups,
    Overlay,
    CenteredColumnLayout,
    TriggerAdvancedValidateMixin
  }
})
export default class TriggerAdvancedUserLimitsSettings extends Mixins<UseFields, InputSetups, TriggerHelper, TriggerInputsSetups>(UseFields, InputSetups, TriggerHelper, TriggerInputsSetups) {
  @VModel({ 'type': Object, 'required': true }) model!: TriggerData

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop({ 'type': Array, 'required': true }) filteredActions!: Array<Array<EntityData>>

  @Prop({ 'type': Boolean, 'required': false, 'default': false }) hasForm!: boolean

  modelCopy: TriggerData | null = null

  isPopupOpen: boolean = false

  get limitActionsGroups(): any {
    return this.modelCopy ? this.modelCopy.limitActions : []
  }

  set limitActionsGroups(value: any) {
    (this.modelCopy as TriggerData).limitActions = value
  }

  get isModified(): boolean {
    return Boolean(
      this.model.limit ||
        this.model.limitInterval ||
        this.model.roundLimitInterval ||
        this.model.limitActions.length
    )
  }

  // get checkValidActionsGroup(): (groups: any) => Array<{[key: string]: string}> {
  //   return checkValidActionsGroup
  // }
  //
  // get disabledSaveButton(): (groups: any) => boolean {
  //   return disabledSaveButton
  // }

  openPopup(): void {
    this.modelCopy = cloneDeep(this.model)
    this.isPopupOpen = true
  }

  saveChanges(): void {
    this.model = this.modelCopy as TriggerData
    this.isPopupOpen = false
  }
}
