var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trigger-advanced-form-actions-settings"},[_c('a-card',{class:{'border-2 border-success': _vm.isModified},attrs:{"hoverable":""},nativeOn:{"click":function($event){return _vm.openPopup.apply(null, arguments)}}},[_c('div',{staticClass:"text-center font-medium hover:text-primary cursor-pointer"},[_vm._v(" "+_vm._s(_vm.$t('trigger_advanced_form_actions_settings'))+" ")])]),_c('overlay',{staticClass:"trigger-advanced__overlay overflow-scroll p-4",attrs:{"id":"overlay_forms"},model:{value:(_vm.isPopupOpen),callback:function ($$v) {_vm.isPopupOpen=$$v},expression:"isPopupOpen"}},[_c('div',{staticClass:"flex flex-row items-center justify-end"},[_c('a-button',{attrs:{"type":"primary","icon":"close"},on:{"click":function($event){_vm.isPopupOpen = false}}})],1),_c('centered-column-layout',[(_vm.modelCopy)?_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h2',[_vm._v(_vm._s(_vm.$t('trigger_advanced_form_actions_settings')))]),_c('select-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.modelCopy,
              'key': 'form',
              'prefix': 'trigger_setup_',
              'options': _vm.setFormsOptions,
              'disabled': _vm.disabled,
            }
          }}}),(_vm.modelCopy.form)?_c('switch-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.modelCopy,
              'key': 'multiplyForm',
              'prefix': 'trigger_setup_',
              'disabled': _vm.disabled,
            }
          }}}):_vm._e(),(_vm.modelCopy.form)?_c('div',{staticClass:"actions-groups-wrapper"},[_c('h2',[_vm._v(_vm._s(_vm.$t('trigger_setup_form_actions')))]),_c('actions-groups',{attrs:{"show-from-to":false,"disabled":_vm.disabled,"selected-form-struct":_vm.setEditorFormStructure,"filtered-actions":_vm.filteredActions},model:{value:(_vm.formActionsGroups),callback:function ($$v) {_vm.formActionsGroups=$$v},expression:"formActionsGroups"}})],1):_vm._e(),_c('trigger-advanced-validate-mixin',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalidTriggerField = ref.invalidTriggerField;
return [_c('div',{staticClass:"flex flex-row items-center justify-end mt-base"},[_c('div',{staticClass:"flex flex-col"},[_c('a-button',{staticClass:"btn-success",attrs:{"icon":"check","disabled":invalid || _vm.disabled || invalidTriggerField !== null},on:{"click":_vm.saveChanges}},[_vm._v(" "+_vm._s(_vm.$t('trigger_advanced_button_save'))+" ")]),(invalidTriggerField)?_c('div',{staticClass:"flex flex-row items-center justify-center mt-2"},[_c('a-icon',{staticClass:"text-danger mr-2",attrs:{"type":"close"}}),_vm._v(" "+_vm._s(_vm.$t(invalidTriggerField))+" ")],1):_vm._e()],1)])]}}],null,true),model:{value:(_vm.formActionsGroups),callback:function ($$v) {_vm.formActionsGroups=$$v},expression:"formActionsGroups"}})]}}],null,false,1599583836)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }