import { TriggerHelper, TriggerInputsSetups } from "@/components/TriggerSetup/logic/mixins/TriggerSetupMixin";

import { UseFields } from "piramis-base-components/src/components/Pi/index";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import { PlaceholdersStack } from "piramis-base-components/src/components/Editor/components/Toolbar/types";

import { Component, Mixins, Prop, VModel } from "vue-property-decorator";

@Component
export class ActionSetupView extends Mixins<UseFields, TriggerInputsSetups, TriggerHelper>(UseFields, TriggerInputsSetups, TriggerHelper) {
  @VModel({ 'type': Object }) model!: any

  @Prop() placeholders!: PlaceholdersStack

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  targets(): Promise<Array<SelectOptionData>> {
    return  Promise.resolve(this.getActionTarget(this.model.type))
  }

  restrictTypesOptions(): Promise<Array<SelectOptionData>> {
    return  Promise.resolve(this.restrictTypes)
  }

  asReplyTypesOptions(): Promise<Array<SelectOptionData>> {
    return  Promise.resolve(this.asReplyTypes)
  }
}
