

























import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from "@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ConditionInvertSettings
  }
})
export default class ThreadIdSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {

  get threadId():Array<number> {
    if (this.conditionData.value.some((id: string | number) => typeof id === 'string')) {
      return this.conditionData.value.map(Number)
    }

    return this.conditionData.value
  }

  set threadId(value:Array<number>) {
    this.conditionData.value = value
  }

  get topicsOptions() {
    return (this.$store.state.chatState.chat.topics as Array<{title: string, id:number}>).map(topic => ({
      label: topic.title,
      value: topic.id
    }))
  }

}
