import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import ChangePointsIntervalActionSetupView from "@/components/TriggerSetup/actions/ChangePointsIntervalAction/ChangePointsIntervalActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': ChangePointsIntervalActionSetupView,
  'structure': {
    'type': ActionsType.ChangePointsIntervalAction,
    'target': "Caller",
    'count' : 0, // can be positive and negative
    'from'  : 0, // can be positive and negative
    'to'    : 0,
    'chance': 100,
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.ChangePointsIntervalAction) }`
}

export default action
