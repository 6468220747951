import { ConditionsType, ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import MessageTextSubstringMatch from './MessageTextSubstringMatch'

import MessageReplyType from './MessageReplyType'
import MessageType from './MessageType'

import CallerType from './CallerType'

import WorkDates from './WorkDates'
import WorkMonthDays from './WorkMonthDays'
import WorkDays from './WorkDays'
import WorkTime from './WorkTime'
import WorkWeekDays from './WorkWeekDays'

import ReplyMessageTextSubstringMatch from './ReplyMessageTextSubstringMatch'
import ThreadId from "./ThreadId";

//wrappers
import RegexpConditionsWrapper from './RegexpConditionsWrapper';
import XpConditionsWrapper from "./XpConditionsWrapper";
import MessageCountConditionsWrapper from './MessageCountConditionsWrapper'
import DaysWithMessagesWrapper from './DaysWithMessagesConditionsWrapper'
import MessageTextConditionsWrapper from "./MessageTextConditionsWrapper";
import MessageTextLenConditionsWrapper from "./MessageTextLenConditionsWrapper";
import MessageWordConditionsWrapper from "./MessageWordConditionsWrapper";
import UserLoginConditionsWrapper from './UserLoginConditionsWrapper'
import UserNameConditionsWrapper from './UserNameConditionsWrapper'
import RankConditionsWrapper from "./RankConditionsWrapper";
import ActionPointsConditionsWrapper from "./ActionPointsConditionsWrapper";
import ReplyActionPointsConditionsWrapper from "./ReplyActionPointsConditionsWrapper";
import ReputationConditionsWrapper from "./ReputationConditionsWrapper";
import ReplyReputationConditionsWrapper from "./ReplyReputationConditionsWrapper";
import FirstUserMessageTimeConditionsWrapper from "./FirstUserMessageTimeConditionsWrapper";
import FirstUserEnterTimeConditionsWrapper from "./FirstUserEnterTimeConditionsWrapper";
import LastUserEnterTimeConditionsWrapper from "./LastUserEnterTimeConditionsWrapper";
import FirstUserActivityConditionsWrapper from "./FirstUserActivityConditionsWrapper";
import ReplyMessageTextConditionsWrapper from "./ReplyMessageTextConditionsWrapper";
import ReplyMessageWordConditionsWrapper from "./ReplyMessageWordConditionsWrapper";

import { chain } from 'lodash';
import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class ConditionHelper extends Vue {

  get conditions(): { [key: string]: EntityData } {
    return chain([
      MessageTextConditionsWrapper,
      MessageTextSubstringMatch,
      MessageWordConditionsWrapper,

      MessageType,
      MessageTextLenConditionsWrapper,

      UserLoginConditionsWrapper,
      UserNameConditionsWrapper,

      RankConditionsWrapper,

      ActionPointsConditionsWrapper,
      ReplyActionPointsConditionsWrapper,

      ReputationConditionsWrapper,
      ReplyReputationConditionsWrapper,

      WorkDays,
      WorkWeekDays,
      WorkDates,
      WorkMonthDays,
      WorkTime,

      FirstUserMessageTimeConditionsWrapper,
      FirstUserEnterTimeConditionsWrapper,
      LastUserEnterTimeConditionsWrapper,
      FirstUserActivityConditionsWrapper,
      MessageCountConditionsWrapper,
      DaysWithMessagesWrapper,

      ReplyMessageTextConditionsWrapper,
      ReplyMessageTextSubstringMatch,
      ReplyMessageWordConditionsWrapper,

      CallerType,
      ThreadId,
      MessageReplyType,
      RegexpConditionsWrapper,
      XpConditionsWrapper,
    ])
        .keyBy('structure.type')
        .value();
  }
}

export const conditionsWrappers: Record<ConditionsWrapperType, Array<ConditionsType>> = {
  [ConditionsWrapperType.RegexpConditionsWrapper]: [
    ConditionsType.MessageTextRegExp,
    ConditionsType.MessageTextMentionEntityType,
    ConditionsType.MessageMentionEntityType,
    ConditionsType.ReplyMessageTextRegExp,
    ConditionsType.ReplyUserLoginRegExp,
    ConditionsType.ReplyUserNameRegExp,
    ConditionsType.UserLoginRegExp,
    ConditionsType.UserNameRegExp,
    ConditionsType.MessagePhoneNumberEntityType
  ],
  [ConditionsWrapperType.XpConditionsWrapper]: [
    ConditionsType.XpEquals,
    ConditionsType.XpLower,
    ConditionsType.XpUpper,
    ConditionsType.ReplyXpEquals,
    ConditionsType.ReplyXpLower,
    ConditionsType.ReplyXpUpper,
  ],
  [ConditionsWrapperType.MessageCountConditionsWrapper]: [
    ConditionsType.MessageCountLower,
    ConditionsType.MessageCountUpper,
    ConditionsType.ReplyMessageCountLower,
    ConditionsType.ReplyMessageCountUpper,
  ],
  [ConditionsWrapperType.DaysWithMessagesConditionsWrapper]: [
    ConditionsType.DaysWithMessagesLower,
    ConditionsType.DaysWithMessagesUpper,
    ConditionsType.ReplyDaysWithMessagesLower,
    ConditionsType.ReplyDaysWithMessagesUpper,
  ],
  [ConditionsWrapperType.MessageTextConditionsWrapper]: [
    ConditionsType.MessageTextFullMatch,
    ConditionsType.MessageTextStartWith,
    ConditionsType.MessageTextEndWith,
  ],
  [ConditionsWrapperType.MessageTextLenConditionsWrapper]: [
    ConditionsType.MessageTextMinLen,
    ConditionsType.MessageTextMaxLen,
    ConditionsType.ReplyMessageTextMinLen,
    ConditionsType.ReplyMessageTextMaxLen,
  ],
  [ConditionsWrapperType.MessageWordConditionsWrapper]: [
    ConditionsType.MessageWordFullMatch,
    ConditionsType.MessageWordStartWith,
    ConditionsType.MessageWordEndWith,
  ],
  [ConditionsWrapperType.UserNameConditionsWrapper]: [
    ConditionsType.UserNameMaxLen,
    ConditionsType.UserNameMinLen,
  ],
  [ConditionsWrapperType.UserLoginConditionsWrapper]: [
    ConditionsType.UserLoginMaxLen,
    ConditionsType.UserLoginMinLen,
  ],
  [ConditionsWrapperType.RankConditionsWrapper]: [
    ConditionsType.RankEquals,
    ConditionsType.RankLower,
    ConditionsType.RankUpper,
  ],
  [ConditionsWrapperType.ActionPointsConditionsWrapper]: [
    ConditionsType.ActionPointsEquals,
    ConditionsType.ActionPointsUpper,
    ConditionsType.ActionPointsLower
  ],
  [ConditionsWrapperType.ReplyActionPointsConditionsWrapper]: [
    ConditionsType.ReplyActionPointsEquals,
    ConditionsType.ReplyActionPointsUpper,
    ConditionsType.ReplyActionPointsLower
  ],
  [ConditionsWrapperType.ReputationConditionsWrapper]: [
    ConditionsType.ReputationEquals,
    ConditionsType.ReputationUpper,
    ConditionsType.ReputationLower,
  ],
  [ConditionsWrapperType.ReplyReputationConditionsWrapper]: [
    ConditionsType.ReplyReputationEquals,
    ConditionsType.ReplyReputationUpper,
    ConditionsType.ReplyReputationLower,
  ],
  [ConditionsWrapperType.FirstUserMessageTimeConditionsWrapper]: [
    ConditionsType.FirstUserMessageTimeMoreThat,
    ConditionsType.FirstUserMessageTimeLowerThat,
  ],
  [ConditionsWrapperType.FirstUserEnterTimeConditionsWrapper]: [
    ConditionsType.FirstUserEnterTimeMoreThat,
    ConditionsType.FirstUserEnterTimeLowerThat,
  ],
  [ConditionsWrapperType.LastUserEnterTimeConditionsWrapper]: [
    ConditionsType.LastUserEnterTimeMoreThat,
    ConditionsType.LastUserEnterTimeLowerThat,
  ],
  [ConditionsWrapperType.FirstUserActivityConditionsWrapper]: [
    ConditionsType.FirstUserActivityMoreThat,
    ConditionsType.FirstUserActivityLowerThat,
  ],
  [ConditionsWrapperType.ReplyMessageTextConditionsWrapper]: [
    ConditionsType.ReplyMessageTextFullMatch,
    ConditionsType.ReplyMessageTextStartWith,
    ConditionsType.ReplyMessageTextEndWith,
  ],
  [ConditionsWrapperType.ReplyMessageWordConditionsWrapper]: [
    ConditionsType.ReplyMessageWordFullMatch,
    ConditionsType.ReplyMessageWordStartWith,
    ConditionsType.ReplyMessageWordEndWith,
  ],
}

export const conditionsGroupsTypes: Record<string, Array<ConditionsType | ConditionsWrapperType>> = {
  messageConditions: [
    ConditionsWrapperType.MessageTextConditionsWrapper,
    ConditionsWrapperType.MessageWordConditionsWrapper,
    ConditionsType.MessageTextSubstringMatch,
  ],
  messagePropertiesConditions: [
    ConditionsWrapperType.MessageTextLenConditionsWrapper,
    ConditionsType.MessageType,
  ],
  userConditions: [
    ConditionsWrapperType.UserLoginConditionsWrapper,
    ConditionsWrapperType.UserNameConditionsWrapper,
  ],
  userRankConditions: [
    ConditionsWrapperType.RankConditionsWrapper
  ],
  userPointsConditions: [
    ConditionsWrapperType.ActionPointsConditionsWrapper,
    ConditionsWrapperType.ReplyActionPointsConditionsWrapper,
  ],
  userReputationPointsConditions: [
    ConditionsWrapperType.ReputationConditionsWrapper,
    ConditionsWrapperType.ReplyReputationConditionsWrapper,
  ],
  dataConditions: [
    ConditionsType.WorkDays,
    ConditionsType.WorkWeekDays,
    ConditionsType.WorkDates,
    ConditionsType.WorkMonthDays,
    ConditionsType.WorkTime,
  ],
  userActionsConditions: [
    ConditionsWrapperType.FirstUserMessageTimeConditionsWrapper,
    ConditionsWrapperType.FirstUserEnterTimeConditionsWrapper,
    ConditionsWrapperType.LastUserEnterTimeConditionsWrapper,
    ConditionsWrapperType.FirstUserActivityConditionsWrapper,
    ConditionsWrapperType.MessageCountConditionsWrapper,
    ConditionsWrapperType.DaysWithMessagesConditionsWrapper,
  ],
  otherConditions: [
    ConditionsWrapperType.RegexpConditionsWrapper,
    ConditionsWrapperType.XpConditionsWrapper,
    ConditionsType.ThreadId,
    ConditionsType.CallerType,
    ConditionsType.MessageReplyType,
  ],
  repliesConditions: [
    ConditionsWrapperType.ReplyMessageTextConditionsWrapper,
    ConditionsWrapperType.ReplyMessageWordConditionsWrapper,
    ConditionsType.ReplyMessageTextSubstringMatch,
  ]
}

