


















import TriggerSetup from '@/components/TriggerSetup/TriggerSetup.vue'
import { RawTriggerData, TriggerData } from '@/components/TriggerSetup/logic/types/types'
import { TriggerFactory } from '@/components/TriggerSetup/logic/factories/trigger-factory'
import Api from '@/includes/logic/Api'
import TriggerControls from '@/components/TriggerSetup/components/TriggerControls/TriggerControls.vue'
import VuTriggers from '@/includes/logic/VuTriggers'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification, successNotification } from '@/includes/NotificationService'
import { BaseViewActionType } from '@/includes/types/Enums'
import { TriggerSettingType } from '@/includes/types/triggers/Enums'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component, Mixins } from 'vue-property-decorator'
import { isEmpty } from 'lodash'

@Component({
  'components': {
    TriggerSetup,
  },
  data() {
    return {
      TriggerControls,
    }
  }
})
export default class Trigger extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  triggerModel: TriggerData | null = null

  triggerFactory: TriggerFactory = new TriggerFactory()

  isTriggerSetupDisabled = false

  get chatId(): number {
    return Number.parseInt(this.$route.params[EntityTypes.CHAT_ID])
  }

  get triggerId(): string | undefined {
    return this.$route.query.triggerId as string | undefined
  }

  get triggerSetupType(): BaseViewActionType {
    return this.$route.params.actionType as BaseViewActionType
  }

  get triggerSettingType(): TriggerSettingType {
    if (this.$route.query.type) {
      return this.$route.query.type as TriggerSettingType
    }

    return TriggerSettingType.Full
  }

  get controlsListeners(): any {
    const listeners: any = {}

    if (!this.isTriggerSetupDisabled) {
      listeners['save'] = this.handleSave
      listeners['save-no-move'] = this.handleSaveNotMove

      if (this.triggerSetupType === BaseViewActionType.Edit) {
        listeners['remove'] = this.handleRemove
      }
    }

    if (this.isTriggerSetupDisabled) {
      listeners['back'] = this.handleBack
    }

    return listeners
  }

  handleBack():void {
    this.$router.back()
  }

  async handleSaveNotMove(rawTriggerData: RawTriggerData): Promise<void> {
    try {
      if (this.triggerSetupType === BaseViewActionType.Edit) {
        rawTriggerData.id = this.triggerId
      }

      this.$baseTemplate.loader.open()

      const { data } = await VuTriggers.setTrigger(rawTriggerData)
      this.$store.commit('enableTrigger', data.id)
      successNotification()

      if (this.$route.params.actionType === 'new') {
        this.$router.push({
          name: 'chat_trigger',
          params: {
            [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id,
            actionType: 'edit',
          },
          query: {
            triggerId: data.id,
          },
        })
      }
    } catch (error) {
      errorNotification(error)
    } finally {
      this.$baseTemplate.loader.close()
    }
  }

  async handleSave(rawTriggerData: RawTriggerData): Promise<void> {
    try {
      if (this.triggerSetupType === BaseViewActionType.Edit) {
        rawTriggerData.id = this.triggerId
      }

      this.$baseTemplate.loader.open()

      const { data } = await VuTriggers.setTrigger(rawTriggerData)

      this.$store.commit('enableTrigger', data.id)
      this.$store.commit('updatedTriggerId', data.id)

      await this.$router.push({
        'name': 'chat_triggers',
        'params': { [EntityTypes.CHAT_ID]: this.chatId.toString() },
      })
    } catch (error) {
      errorNotification(error)
    } finally {
      this.$baseTemplate.loader.close()
    }
  }

  async handleRemove(): Promise<void> {
    try {
      await VuTriggers.deleteFrontendTrigger(this.triggerId)

      await this.$router.push({
        name: 'chat_triggers',
        params: { [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id },
      })
    } catch (error) {
      errorNotification(error)
    }
  }

  triggerNameIfQuery():string {
    const triggerName = this.$route.query.name

    if (triggerName) return triggerName.toString()
    return ''
  }

  async createNewTriggerFromExists (id: string | undefined): Promise<TriggerData | null> {
    try {
      const { data: { trigger } } = await Api.getTrigger('tg', { id })
      return this.triggerFactory.createNewTriggerFromExists(trigger)
    } catch (error) {
      errorNotification(error)
      await this.$router.push({ name: 'chat_triggers' })
    }

    return null
  }

  async created(): Promise<void> {
    const chatId = this.chatId
    let triggerData: TriggerData | null = null

    if (this.triggerSetupType === BaseViewActionType.New) {
      if (!isEmpty(this.$store.state.triggersState.newTrigger)) {
        triggerData = this.triggerFactory.createNewTriggerFromExists(this.$store.state.triggersState.newTrigger)
        this.$store.commit('resetNewTrigger')
      } else {
        triggerData = this.triggerFactory.createNewTrigger({ chatId })
        triggerData.name = this.triggerNameIfQuery()
      }
    }

    if (this.triggerSetupType === BaseViewActionType.Edit) {
      if (this.triggerId) {
        triggerData = await this.createNewTriggerFromExists(this.triggerId) || null
      }
    }

    this.triggerModel = triggerData || null
  }

  mounted():void {
    this.$baseTemplate.saveButton.hide()
  }

  destroyed():void {
    this.$baseTemplate.saveButton.show()
  }
}
