


































































































































import { getTariffTranslationKey } from '@/includes/helpers/tariffHelper'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { ActionsType, TriggerData } from '@/components/TriggerSetup/logic/types/types'
import { TriggerFactory } from '@/components/TriggerSetup/logic/factories/trigger-factory'
import ConditionsGroups from '@/components/TriggerSetup/components/ConditionsGroups/ConditionsGroups.vue'
import ActionsGroups from '@/components/TriggerSetup/components/ActionsGroups/ActionsGroups.vue'
import TriggerAdvancedFormActionsSettings
  from '@/components/TriggerSetup/components/TriggerAdvancedFormActionsSettings/TriggerAdvancedFormActionsSettings.vue'
import TriggerAdvancedConditionSettings
  from '@/components/TriggerSetup/components/TriggerAdvancedConditionSettings/TriggerAdvancedConditionSettings.vue'
import TriggerAdvancedUserLimitsSettings
  from '@/components/TriggerSetup/components/TriggerAdvancedUserLimitsSettings/TriggerAdvancedUserLimitsSettings.vue'
import TriggerAdvancedChatUserLimitsSettings
  from '@/components/TriggerSetup/components/TriggerAdvancedChatUserLimitsSettings/TriggerAdvancedChatUserLimitsSettings.vue'
import TriggerAdvancedWarnActionsSettings
  from '@/components/TriggerSetup/components/TriggerAdvancedWarnActionsSettings/TriggerAdvancedWarnActionsSettings.vue'
import { TriggerControlsMixin } from '@/components/TriggerSetup/logic/mixins/trigger-controls.mixin'
import checkChat from '@/assets/utils/checkChat'
import { InputSetups } from '@/mixins/input-setups'
import ActionsHelper, { actionGroupsTypes } from '@/components/TriggerSetup/actions'
import { TriggerHelper } from './logic/mixins/TriggerSetupMixin'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import ConditionHelper, { conditionsWrappers } from '@/components/TriggerSetup/conditions/index'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { TriggerSettingType } from '@/includes/types/triggers/Enums'
import TriggerExtraSettings from '@/components/TriggerSetup/components/TriggerExtraSettings.vue'
import TriggerValidationMixin from '@/components/TriggerSetup/components/TriggerAdvancedValidate/TriggerValidationMixin'
import { BRAND_TAG } from '@/includes/constants'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import { TagData } from 'piramis-base-components/src/components/Tags/types'

import { Component, Mixins, VModel, Prop, Watch } from 'vue-property-decorator'
import { snakeCase, cloneDeep } from 'lodash'

@Component({
  'components': {
    TriggerExtraSettings,
    TriggerAdvancedFormActionsSettings,
    ConditionsGroups,
    ActionsGroups,
    TriggerAdvancedConditionSettings,
    TriggerAdvancedUserLimitsSettings,
    TriggerAdvancedChatUserLimitsSettings,
    TriggerAdvancedWarnActionsSettings,
    NotAvailableOptionsOverlay
  },
  data() {
    return {
      ListDirection,
      getTariffTranslationKey,
      TriggerSettingType
    }
  },
  'methods': {
    snakeCase
  },
  mixins: [ UseFields, InputSetups ]
})
export default class TriggerSetup extends Mixins<TariffsTagsHelper, TriggerHelper, ActionsHelper, ConditionHelper, TriggerValidationMixin>(TariffsTagsHelper, TriggerHelper, ActionsHelper, ConditionHelper, TriggerValidationMixin) {
  @VModel({ 'type': Object }) triggerData!: TriggerData

  @Prop({ 'required': true }) readonly controls!: { view: typeof TriggerControlsMixin, props: Record<string, any>, listeners: Record<string, any> }

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop({ 'type': String, required: true }) readonly triggerSettingType!: TriggerSettingType

  triggerFactory: TriggerFactory = new TriggerFactory()

  conditionsList: Array<EntityData> = []

  get validTrigger(): Record<string, boolean> {
    return {
      trigger_validate_name: !!this.triggerData.name,
      trigger_validate_conditions: this.hasTriggerConditionGroups,
      trigger_validate_conditions_values: this.conditionValuesValid,
      trigger_validate_actions: this.trigger_validate_actions,
      trigger_validate_actions_values: this.trigger_validate_actions_values,
    }
  }

  get invalidTriggerFields():string | null {
    return this.firstInvalidTriggerField(this.validTrigger)
  }

  get hasTriggerConditionGroups():boolean {
    return this.triggerData.conditions.length > 0 && this.triggerData.conditions[0].value.length > 0
  }

  get conditionValuesValid():boolean {
    if (this.hasTriggerConditionGroups) {
      return !this.triggerData.conditions.some(c => c.value.some(i => {
        if (Object.keys(conditionsWrappers).includes(i.value.structure.type)) {
          const currentConditionFromWrapper = i.value.structure.currentCondition

          if (Array.isArray(i.value.structure.currentCondition.value)) {
            return !currentConditionFromWrapper.value.length
          }
        } else {
          return !i.value.structure.value.length
        }
      }))
    }

    return false
  }

  get trigger_validate_actions():boolean {
    return this.hasActionGroups(this.triggerData.actions)
      || this.hasActionGroups(this.triggerData.limitActions)
      || this.hasActionGroups(this.triggerData.globalLimitActions)
  }

  get trigger_validate_actions_values():boolean {
    return this.actionValuesValid(this.triggerData.actions)
      || this.actionValuesValid(this.triggerData.limitActions)
      || this.actionValuesValid(this.triggerData.globalLimitActions)
  }

  get isChatTriggerType(): boolean {
    return this.triggerType === 'chat'
  }

  get triggerType(): any {
    return checkChat(this.$route.path) ? 'chat' : 'global'
  }

  get filterActions() {
    return this.getFilteredActions('form')
  }

  get defaultChatActions() {
    return this.getFilteredActions('chat')
  }

  getTagStruct(optionKey: string): Array<TagData> | null {
    if ([
      'trigger_action_send_private_message_action',
      'trigger_action_change_points_interval_action',
      'trigger_action_set_points_action',
    ].includes(optionKey)) {
      return [ {
        text: 'Advanced',
        color: this.$store.state.themeConfig.config.tariffs.advanced.color
      } ]
    }

    return this.getTagsByFieldKey(optionKey)
  }

  tariffTagIfPayed(type:any, optionKey:string) {
    return this.isAvailableTriggerFunction(type, optionKey) ? null : this.getTagStruct(optionKey)
  }

  preparedConditions(): Array<EntityData> {
    const conditions = cloneDeep(this.conditions)

    return Object.values(conditions).map(condition => {
      const conditionType = condition.structure.type
      const conditionOptionKey = `trigger_condition_${ snakeCase(conditionType) }`

      if (!condition.limited) {
        condition.limited = this.tariffTagIfPayed(conditionType, conditionOptionKey)
      }

      return condition
    })
  }

  getFilteredActions(filterType: 'chat' | 'form'): Array<EntityData> {
    const newActionsList: Array<EntityData> = []
    const brandTagIfChatNotBrand = () => this.$store.getters.getHaveLicense && this.$store.getters.getHaveBrand && this.$store.getters.isChatLicenseExists ? null : BRAND_TAG

    Object.values(this.actions).forEach((action: any) => {
      const actionType = action.structure.type
      const actionOptionKey = `trigger_action_${ snakeCase(actionType) }`

      if (filterType === 'form') {
        if (actionGroupsTypes['formActions'].includes(actionType)) {
          if (actionType === ActionsType.SendFormResultToTarget) {
            action.limited = brandTagIfChatNotBrand()
          } else {
            action.limited = this.tariffTagIfPayed(actionType, actionOptionKey)
          }

          newActionsList.push(action)
        }
      } else {
        if (!actionGroupsTypes['formActions'].includes(actionType)) {
          if (actionType === ActionsType.SendMessageToTargetAction) {
            action.limited = brandTagIfChatNotBrand()
          } else {
            action.limited = this.tariffTagIfPayed(actionType, actionOptionKey)
          }

          newActionsList.push(action)
        }
      }
    })

    return newActionsList
  }

  created(): void {
    if (this.isChatTriggerType && !this.getTagsByFieldKey('forms')) {
      this.$store.dispatch('getForms')
    }

    this.conditionsList = this.preparedConditions()
  }
}
