import { render, staticRenderFns } from "./SendMessageToTargetActionSetup.vue?vue&type=template&id=4d039e80&scoped=true&"
import script from "./SendMessageToTargetActionSetup.vue?vue&type=script&lang=ts&"
export * from "./SendMessageToTargetActionSetup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d039e80",
  null
  
)

export default component.exports