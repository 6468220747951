


































import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import triggersMixin from "@/mixins/triggersMixin";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'mixins': [ triggersMixin ],
  'components': {
    ActionChance
  },
})
export default class ChangeTriggerActiveStateActionSetupView extends Mixins<ActionSetupView>(ActionSetupView) {

}
