var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trigger-advanced-user-limits-settings"},[_c('a-card',{class:{'border-2 border-success': _vm.isModified},attrs:{"hoverable":""},nativeOn:{"click":function($event){return _vm.openPopup.apply(null, arguments)}}},[_c('div',{staticClass:"text-center font-medium hover:text-primary cursor-pointer"},[_vm._v(" "+_vm._s(_vm.$t('trigger_advanced_user_limits_settings'))+" ")])]),_c('overlay',{staticClass:"trigger-advanced__overlay overflow-scroll p-4",model:{value:(_vm.isPopupOpen),callback:function ($$v) {_vm.isPopupOpen=$$v},expression:"isPopupOpen"}},[_c('div',{staticClass:"flex flex-row items-center justify-end"},[_c('a-button',{attrs:{"type":"primary","icon":"close"},on:{"click":function($event){_vm.isPopupOpen = false}}})],1),_c('centered-column-layout',[(_vm.modelCopy)?_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h2',[_vm._v(_vm._s(_vm.$t('trigger_advanced_user_limits_settings')))]),_c('number-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.modelCopy,
              'key': 'limit',
              'prefix': 'trigger_setup_',
              'disabled': _vm.disabled,
            }
          }}}),_c('time-unit-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.modelCopy,
              'key': 'limitInterval',
              'prefix': 'trigger_setup_',
              'disabled': _vm.disabled,
            }
          }}}),_c('switch-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.modelCopy,
              'key': 'roundLimitInterval',
              'prefix': 'trigger_setup_',
              'disabled': _vm.disabled,
            }
          }}}),_c('h2',[_vm._v(_vm._s(_vm.$t('trigger_setup_limit_actions')))]),_c('actions-groups',{attrs:{"show-from-to":true,"disabled":_vm.disabled,"filtered-actions":_vm.filteredActions,"has-form":_vm.hasForm},model:{value:(_vm.limitActionsGroups),callback:function ($$v) {_vm.limitActionsGroups=$$v},expression:"limitActionsGroups"}}),_c('trigger-advanced-validate-mixin',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalidTriggerField = ref.invalidTriggerField;
return [_c('div',{staticClass:"flex flex-row items-center justify-end mt-base"},[_c('div',{staticClass:"flex flex-col"},[_c('a-button',{staticClass:"btn-success",attrs:{"icon":"check","disabled":invalid || _vm.disabled || invalidTriggerField !== null},on:{"click":_vm.saveChanges}},[_vm._v(" "+_vm._s(_vm.$t('trigger_advanced_button_save'))+" ")]),(invalidTriggerField)?_c('div',{staticClass:"flex flex-row items-center justify-center mt-2"},[_c('a-icon',{staticClass:"mr-2 text-danger",attrs:{"type":"close"}}),_vm._v(" "+_vm._s(_vm.$t(invalidTriggerField))+" ")],1):_vm._e()],1)])]}}],null,true),model:{value:(_vm.limitActionsGroups),callback:function ($$v) {_vm.limitActionsGroups=$$v},expression:"limitActionsGroups"}})]}}],null,false,2512759967)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }