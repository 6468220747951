import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { snakeCase } from "lodash";
import DaysWithMessagesLower from "./conditions/DaysWithMessagesLower";
import DaysWithMessagesConditionsWrapper from "./DaysWithMessagesConditionsWrapper.vue";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

const condition: EntityData = {
  'setupView': DaysWithMessagesConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.DaysWithMessagesConditionsWrapper,
    'currentConditionType': DaysWithMessagesLower.structure.type,
    'currentCondition': DaysWithMessagesLower.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.DaysWithMessagesConditionsWrapper) }`
}

export default condition
