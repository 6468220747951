









import { ActionSetupView } from '@/components/TriggerSetup/logic/mixins/action-setup-view.mixin'
import ActionInterval from '@/components/TriggerSetup/components/ActionInterval/ActionInterval.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionInterval
  }
})
export default class ChangePointsIntervalActionSetupView extends Mixins<ActionSetupView>(ActionSetupView) {

}
