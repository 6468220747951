import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import ReplyReputationEquals from "./conditions/ReplyReputationEquals";
import ReplyReputationConditionsWrapper from "./ReplyReputationConditionsWrapper.vue";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': ReplyReputationConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.ReplyReputationConditionsWrapper,
    'currentConditionType': ReplyReputationEquals.structure.type,
    'currentCondition': ReplyReputationEquals.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.ReplyReputationConditionsWrapper) }`
}

export default condition
