var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('select-input',{attrs:{"setup":{
    'func': _vm.triggerInputSetupFn,
    'args': {
      model: _vm.model,
      'key': 'asReply',
      'prefix': 'trigger_action_',
      'options': _vm.asReplyTypesOptions,
      disabled: _vm.disabled,
      'validation': 'required',
    }
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }