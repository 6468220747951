import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import CalculatePropertyTriggerActionView from "./CalculatePropertyTriggerActionView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': CalculatePropertyTriggerActionView,
  'structure': {
    'type'              : ActionsType.CalculatePropertyTriggerAction,
    'propId'            : "",
    'targetType'        : "",
    'actionType'        : "",
    'calculateValue'    : "",
    'chance'            : 100
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.CalculatePropertyTriggerAction) }`
}

export default action
