var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"send-message-to-target-action"},[_c('action-text',{attrs:{"disabled":_vm.disabled,"placeholders":_vm.TRIGGERS_PLACEHOLDERS},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        disabled: _vm.disabled,
        'key': 'target',
        'prefix': 'trigger_action_receive_',
        'validation': 'required',
      }
    }}}),(_vm.hasForm)?_c('action-form-button-action',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e(),_c('action-chance',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }