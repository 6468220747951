import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import SendMessageActionSetupView
  from "@/components/TriggerSetup/actions/SendMessageAction/SendMessageActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': SendMessageActionSetupView,
  'structure': {
    'type': ActionsType.SendMessageAction,
    'formButtonAction': "",
    'text': [ {
      "text": "",
      "buttons": [],
      "remove_after": 0,
      "send_after": 0,
      "pin": false,
      "disable_link_preview": false,
      "disable_notify": false,
      "remove_previous": false
    } ],
    'asReply': 'None',
    'chance': 100,
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.SendMessageAction) }`
}

export default action
