





















































































































































import { TriggerAction, } from '@/components/TriggerSetup/logic/types/types'
import { TriggerFactory } from '@/components/TriggerSetup/logic/factories/trigger-factory'
import CreateAction from '@/components/TriggerSetup/components/CreateAction/CreateAction.vue'
import { TriggerHelper, TriggerInputsSetups } from '@/components/TriggerSetup/logic/mixins/TriggerSetupMixin'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { InputSetups } from '@/mixins/input-setups'
import ActionsHelper from '@/components/TriggerSetup/actions'

import ConfigGroup from 'piramis-base-components/src/components/ConfigGroup/ConfigGroup.vue'
import BlockMovingControls from 'piramis-base-components/src/components/BlockMovingControls/BlockMovingControls.vue'
import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { MessageEditorWithMediaData } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'

import { snakeCase } from 'lodash'
import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  'components': {
    CreateAction,
    ConfigGroup,
    BlockMovingControls,
    List,
  },
  data() {
    return {
      ListDirection
    }
  },
  'methods': {
    snakeCase
  }
})
export default class ActionsGroups extends Mixins<UseFields, InputSetups, TriggerHelper, TriggerInputsSetups, ActionsHelper>(UseFields, InputSetups, TriggerHelper, TriggerInputsSetups, ActionsHelper) {
  @VModel({ 'type': Array, 'required': true }) model!: any

  @Prop({
    'type': Array,
    'required': false,
    'default': () => []
  }) selectedFormStruct!: Array<MessageEditorWithMediaData>

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop({ 'type': Boolean, 'default': false }) readonly showFromTo!: boolean

  @Prop({ 'type': Array, 'required': true }) filteredActions!: Array<Array<EntityData>>

  @Prop({ 'type': Boolean, 'required': false, 'default': false }) hasForm!: boolean

  triggerFactory: TriggerFactory = new TriggerFactory()

  addActionGroup(actionGroups: any): void {
    actionGroups.push(this.triggerFactory.createActionGroup())
    this.$emit('input', this.model)
  }

  createAction(actionGroup: any, item: any): void {
    if ([ 'SendFormToPrivate', 'SendFormToChat', 'SendFormResultToTarget' ].includes(item.structure.type)) {
      if (this.selectedFormStruct) {
        item.structure.text = this.selectedFormStruct.slice()
      }
    }

    actionGroup.value.actions.push(this.triggerFactory.createAction(item))
    this.$emit('input', this.model)
  }

  processOldActionTypes(action: any) {
    if ([ 'ChangeReputationAction', 'ChangePointsAction' ].includes(action.type)) {
      switch (action.type) {
        case 'ChangeReputationAction':
          action.type = 'ChangeReputationIntervalAction'
          break
        case 'ChangePointsAction':
          action.type = 'ChangePointsIntervalAction'
          break
      }
      if (action.count !== undefined) {
        action.from = Number(action.count)
        action.to = Number(action.count)
        delete action.count
      }
    }
  }

  created(): void {
    const actionGroups: Array<TriggerAction> = this.model.slice()
    actionGroups.forEach((actionGroup: any) => {
      actionGroup.value.actions.forEach((action: any) => {
        if ([ 'ChangeReputationAction', 'ChangePointsAction' ].includes(action.value.type)) {
          this.processOldActionTypes(action.value)
        }
      })
    })
  }
}
