import { TriggerHelper, TriggerInputsSetups } from "@/components/TriggerSetup/logic/mixins/TriggerSetupMixin";

import { UseFields } from 'piramis-base-components/src/components/Pi';
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component
export class ConditionSetupView extends Mixins<UseFields, TriggerInputsSetups, TriggerHelper>(UseFields, TriggerInputsSetups, TriggerHelper) {
  @VModel({ 'type': Object }) conditionData!: any

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  filters(): Promise<Array<SelectOptionData>> {
    return Promise.resolve(this.conditionFilters)
  }

  caller(): Promise<Array<SelectOptionData>> {
    return Promise.resolve(this.conditionCaller)
  }

  messageType(): Promise<Array<SelectOptionData>> {
    return Promise.resolve(this.conditionMessageType)
  }

  messageContentTypes(): Promise<Array<SelectOptionData>> {
    return Promise.resolve(this.messageTypeValues)
  }

  conditionCallers(): Promise<Array<SelectOptionData>> {
    return Promise.resolve(this.conditionCaller)
  }
}
