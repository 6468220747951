




























import { TriggerHelper } from '@/components/TriggerSetup/logic/mixins/TriggerSetupMixin'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'

import DefaultList from 'piramis-base-components/src/components/Pi/components/DefaultList/DefaultList.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import { Entity, Item, ItemIcon } from 'piramis-base-components/src/components/Pi/components/DefaultList/logic/types'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import { snakeCase } from 'lodash'
import { Guid } from 'guid-typescript'

@Component({
  'components': {
    DefaultList,
    Tags
  },

  'data': () => ({
    ListDirection,
  }),

  'methods': {
    snakeCase
  }
})
export default class EntitySelect extends Mixins(UseFields, TriggerHelper) {
  @Prop({ 'required': true }) readonly entitiesConfig!: { entities: Array<EntityData>, groupsTypes: { [key: string]: Array<string> }, entitiesIcons: { [key: string]: ItemIcon } }

  @Prop({ 'type': String, 'required': true, }) readonly buttonTitle!: string

  @Prop({ 'type': String, 'required': true, }) readonly popupTitle!: string

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Emit()
  select(value: Item): EntityData {
    this.isPopupOpen = false
    return this.entitiesConfig.entities.find(entity => entity.titleKey === value.key) as EntityData
  }

  isPopupOpen = false

  get icons() {
    return this.entitiesConfig.entitiesIcons
  }

  get groupsTypes(): { [key: string]: Array<string> } {
    return this.entitiesConfig.groupsTypes
  }

  getConfig(list: Array<EntityData>): Array<Entity> {
    const filteredEntitiesList: Array<Entity> = []
    list.forEach(listItem => {

      let groupTitle: string | null = ''
      Object.entries(this.groupsTypes).forEach(entre => {
        if (entre[1].includes(listItem.structure.type)) {
          groupTitle = this.$t(`trigger_group_${ snakeCase(entre[0]) }`).toString()
        }
      })
      let helpMessage = ''
      if (this.$te(`trigger_action_${ snakeCase(listItem.structure.type) }_help_message`)) {
        helpMessage = this.$t(`trigger_action_${ snakeCase(listItem.structure.type) }_help_message`).toString()
      } else if (this.$te(`trigger_condition_${ snakeCase(listItem.structure.type) }_help_message`)) {
        helpMessage = this.$t(`trigger_condition_${ snakeCase(listItem.structure.type) }_help_message`).toString()
      }
      if (filteredEntitiesList.some(filteredItem => filteredItem.group && filteredItem.group.title === groupTitle)) {
        const findItem: Entity | undefined = filteredEntitiesList.find(filteredItem => filteredItem.group && filteredItem.group.title === groupTitle)
        if (findItem) {

          (findItem.group?.groupItems as Array<Item>).push({
            title: this.$t(listItem.titleKey).toString(),
            limited: typeof listItem.limited === 'function' ? listItem.limited() : listItem.limited as Array<{ text: string, color: string }>,
            helpMessage: helpMessage,
            icon: this.icons[listItem.structure.type],
            key: listItem.titleKey,
          })
        }
      } else {

        filteredEntitiesList.push({
          group: {
            title: groupTitle,
            guid: Guid.create().toString(),
            groupItems: [ {
              title: this.$t(listItem.titleKey).toString(),
              limited: listItem.limited as Array<{ text: string, color: string }>,
              helpMessage: helpMessage,
              icon: this.icons[listItem.structure.type],
              key: listItem.titleKey
            } ],
          }
        })
      }
    })
    return filteredEntitiesList
  }
}
