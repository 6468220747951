var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"action-text_wrapper"},[_c('multi-message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': Object.assign({}, {model: _vm.model,
        'key': 'text',
        'prefix': ("trigger_action_" + (_vm.titlePrefix ? _vm.titlePrefix+'_' : '')),
        placeholders: _vm.placeholders,
        disabled: _vm.disabled,
        'validation': 'required',
        'availableButtonsTypes': _vm.defaultMessageEditorButtons,
        'targetToUpload': function () { return _vm.groupUploadTarget; },
        'editorMode': _vm.MediaEditorMode.TelegramShort,
        'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
        'base-api-url': 'api.chatkeeper.app/cabinet'},
        _vm.topics,
        {'blacklistMediaButtonTypes': [ _vm.FileType.MessagePreview ]})
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }