var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calculate-property-trigger-action"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'propId',
        'prefix': 'trigger_action_',
        'options': _vm.getPropIds,
        'clearable': false,
        'validation': 'required',
        disabled: _vm.disabled,
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'actionType',
        'prefix': 'trigger_action_',
        'options': _vm.propertyActionTypes,
        'clearable': false,
        'validation': 'required',
        disabled: _vm.disabled,
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'targetType',
        'prefix': 'trigger_action_',
        'options': _vm.propertyTargetTypes,
        'clearable': false,
        'validation': 'required',
        disabled: _vm.disabled,
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'calculateType',
        'prefix': 'trigger_action_',
        'options': _vm.valueCalculateTypes,
        'clearable': false,
        'validation': 'required',
        disabled: _vm.disabled,
      }
    }}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'calculateValue',
        'prefix': 'trigger_action_',
        disabled: _vm.disabled,
        'validation': 'required',
      }
    }}}),_c('action-chance',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }