import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import ThreadIdSetupView from "./ThreadIdSetupView.vue";
import { snakeCase } from "lodash";
import store from "@/store/store";
import { TagData } from "piramis-base-components/src/components/Tags/types";
import { FORUM_TAG } from '@/includes/constants'

const conditionLimited = (): null | Array<TagData> => {
  const startTag = { text: 'Starter', color: store.state.themeConfig.config.tariffs.starter.color };

  const tags = []

  if ((store.state.chatState.chat as any).type !== 'forum') {
    tags.push(...FORUM_TAG)
  }

  if (!store.getters.isChatLicenseExists) {
    tags.push(startTag)
  }

  return tags.length ? tags : null
}

const condition: EntityData = {
  'setupView': ThreadIdSetupView,
  'structure': {
    'type': ConditionsType.ThreadId,
    ...baseCondition,
  } as TriggerCondition,
  'titleKey': `trigger_condition_${ snakeCase(ConditionsType.ThreadId) }`,
  'limited': conditionLimited
}

export default condition
