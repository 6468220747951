import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import {
  ActionsType,
  ActionsWrapperType,
  ConditionsType,
  ConditionsWrapperType
} from "@/components/TriggerSetup/logic/types/types";

import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types';

import { Vue, Component, Mixins } from 'vue-property-decorator'

@Component
export class TriggerHelper extends Mixins<TariffsTagsHelper>(TariffsTagsHelper) {
  triggerLimits: Array<ActionsType | ActionsWrapperType | ConditionsType | ConditionsWrapperType> = [
    ConditionsType.RankEquals,
    ConditionsType.RankLower,
    ConditionsType.RankUpper,
    ConditionsType.ActionPointsEquals,
    ConditionsType.ActionPointsLower,
    ConditionsType.ActionPointsUpper,
    ConditionsType.ReputationEquals,
    ConditionsType.ReputationLower,
    ConditionsType.ReputationUpper,
    ConditionsType.ReplyActionPointsEquals,
    ConditionsType.ReplyActionPointsLower,
    ConditionsType.ReplyActionPointsUpper,
    ConditionsType.ReplyReputationEquals,
    ConditionsType.ReplyReputationLower,
    ConditionsType.ReplyReputationUpper,
    ConditionsType.WorkDays,
    ConditionsType.WorkMonthDays,
    ConditionsType.WorkWeekDays,
    ConditionsType.WorkDates,
    ConditionsType.WorkTime,
    ActionsType.SendFormResultToTarget,
    ActionsType.SendFormToChat,
    ActionsType.SendFormToPrivate,
    ActionsType.SendFormToChat,
    ActionsType.ForwardReplyMessageToTargetAction,
    ActionsType.ForwardMessageToTargetAction,
    ActionsType.CalculatePropertyTriggerAction,
    ActionsType.SendMessageToCalculatedTargetAction,
    ActionsType.SendPrivateMessageAction,
    ActionsType.ChangePointsIntervalAction,
    ActionsType.SetPointsAction
  ]

  get actionExecuteType(): Array<SelectOptionData> {
    return [
      {
        label: this.$t("trigger_action_execute_type_all").toString(),
        value: "All",
      }, {
        label: this.$t("trigger_action_execute_type_random").toString(),
        value: "Random",
      },
    ];
  }

  get restrictTypes(): Array<SelectOptionData> {
    return [
      {
        label: this.$t("trigger_action_restrict_type_any").toString(),
        value: null
      },
      {
        label: this.$t("trigger_action_restrict_type_media").toString(),
        value: "Media",
      },
      {
        label: this.$t("trigger_action_restrict_type_inline").toString(),
        value: "Inline",
      },
      {
        label: this.$t("trigger_action_restrict_type_invite").toString(),
        value: "Invite",
      },
      {
        label: this.$t("trigger_action_restrict_type_pool").toString(),
        value: "Pool",
      },
    ];
  }

  get asReplyTypes(): Array<SelectOptionData> {
    return [
      {
        label: this.$t("trigger_action_as_reply_types_none").toString(),
        value: "None",
      }, {
        label: this.$t("trigger_action_as_reply_types_reply").toString(),
        value: "Reply",
      }, {
        label: this.$t("trigger_action_as_reply_types_reply_to_reply").toString(),
        value: "ReplyToReply",
      },
    ]
  }

  get messageTypeValues(): Array<SelectOptionData> {
    return [
      {
        label: this.$t("trigger_condition_message_type_audio").toString(),
        value: "Audio",
      }, {
        label: this.$t("trigger_condition_message_type_document").toString(),
        value: "Document",
      }, {
        label: this.$t("trigger_condition_message_type_sticker").toString(),
        value: "Sticker",
      }, {
        label: this.$t("trigger_condition_message_type_photo").toString(),
        value: "Photo",
      }, {
        label: this.$t("trigger_condition_message_type_photo_file").toString(),
        value: "PhotoFile",
      }, {
        label: this.$t("trigger_condition_message_type_video").toString(),
        value: "Video",
      }, {
        label: this.$t("trigger_condition_message_type_video_file").toString(),
        value: "VideoFile",
      }, {
        label: this.$t("trigger_condition_message_type_animation").toString(),
        value: "Animation",
      }, {
        label: this.$t("trigger_condition_message_type_contact").toString(),
        value: "Contact",
      }, {
        label: this.$t("trigger_condition_message_type_location").toString(),
        value: "Location",
      }, /*{
         label: this.$t("trigger_condition_message_type_venue"),
         value.toString(): "Venue",
         }, */{
        label: this.$t("trigger_condition_message_type_voice").toString(),
        value: "Voice",
      }, {
        label: this.$t("trigger_condition_message_type_game").toString(),
        value: "Game",
      }, /*{
         label: this.$t("trigger_condition_message_type_invoice"),
         value.toString(): "Invoice",
         },*/ {
        label: this.$t("trigger_condition_message_type_video_note").toString(),
        value: "VideoNote",
      }, /*{
         label: this.$t("trigger_condition_message_type_passport_data"),
         value.toString(): "PassportData",
         },*/ {
        label: this.$t("trigger_condition_message_type_poll").toString(),
        value: "Poll",
      },
    ];
  }

  get conditionFilters(): Array<SelectOptionData> {
    return [
      {
        label: this.$t("trigger_condition_case_insensitive").toString(),
        value: "CaseInsensitive",
      }, {
        label: this.$t("trigger_condition_remove_all_spaces").toString(),
        value: "RemoveAllSpaces",
      }, {
        label: this.$t("trigger_condition_remove_symbols").toString(),
        value: "RemoveSymbols",
      }, {
        label: this.$t("trigger_condition_remove_digits").toString(),
        value: "RemoveDigits",
      }, {
        label: this.$t("trigger_condition_remove_emoji").toString(),
        value: "RemoveEmoji",
      }, {
        label: this.$t("trigger_condition_remove_mention").toString(),
        value: "RemoveMention",
      }, {
        label: this.$t("trigger_condition_remove_urls").toString(),
        value: "RemoveUrls",
      }
      // {
      //   label: this.$t("trigger_condition_trim").toString(),
      //   value: "Trim",
      // },{
      //   label: this.$t("trigger_condition_remove_white_spaces").toString(),
      //   value: "RemoveWhitespaces",
      // },
    ];
  }

  get conditionCaller(): Array<SelectOptionData> {
    return [
      {
        label: this.$t("trigger_condition_anybody").toString(),
        value: "Anybody",
      }, {
        label: this.$t("trigger_condition_admins").toString(),
        value: "Admins",
      }, {
        label: this.$t("trigger_condition_users").toString(),
        value: "Users",
      }, {
        label: this.$t("trigger_condition_creator").toString(),
        value: "Creator",
      }, {
        label: this.$t("trigger_condition_all_admins").toString(),
        value: "AllAdmins",
      }, {
        label: this.$t("trigger_condition_chat_admins").toString(),
        value: "ChatAdmins",
      },
      {
        label: this.$t("trigger_condition_channel_user").toString(),
        value: 'ChannelUser'
      }
    ];
  }

  get conditionMessageType(): Array<SelectOptionData> {
    return [
      {
        label: this.$t("trigger_condition_any").toString(),
        value: "Any",
      }, {
        label: this.$t("trigger_condition_replies").toString(),
        value: "Replies",
      }, {
        label: this.$t("trigger_condition_replies_to_bot").toString(),
        value: "RepliesToBot",
      }, {
        label: this.$t("trigger_condition_replies_to_user").toString(),
        value: "RepliesToUser",
      }, {
        label: this.$t("trigger_condition_replies_to_admin").toString(),
        value: "RepliesToAdmin",
      }, {
        label: this.$t("trigger_condition_replies_to_not_admin").toString(),
        value: "RepliesToNotAdmin",
      },{
        label: this.$t("trigger_condition_replies_to_me").toString(),
        value: "RepliesToMe",
      }, {
        label: this.$t("trigger_condition_no_replies").toString(),
        value: "NoReplies",
      }, {
        label: this.$t("trigger_condition_first_user_message").toString(),
        value: "FirstUserMessage",
      },
      {
        label: this.$t('trigger_condition_replies_to_binded_channel').toString(),
        value: 'RepliesToBindedChannel'
      },
      {
        label: this.$t('trigger_condition_replies_to_channel_user').toString(),
        value: 'RepliesToChannelUser'
      },
      {
        label: this.$t('trigger_condition_message_in_comments').toString(),
        value: 'MessageInComments'
      },
      {
        label: this.$t("trigger_condition_replies_to_self").toString(),
        value: "RepliesToSelf",
      },
    ];
  }

  isAvailableTriggerFunction(type: ActionsType | ActionsWrapperType | ConditionsType | ConditionsWrapperType, key: string): boolean {
    if (this.triggerLimits.includes(type)) {
      if ([
        "trigger_action_send_private_message_action",
        "trigger_action_change_points_interval_action",
        "trigger_action_set_points_action"
      ].includes(key) && this.$store.getters.isChatAtLeastAdvanced) {
        return true
      }

      return !this.getTagsByFieldKey(key)
    } else {
      return true
    }
  }

  getActionTarget(type: ActionsType): Array<SelectOptionData> {
    const targetOptions: Array<SelectOptionData> = [
      {
        label: this.$t("trigger_action_target_caller_and_reply").toString(),
        value: "CallerAndReply",
      }, {
        label: this.$t("trigger_action_target_caller").toString(),
        value: "Caller",
      }, {
        label: this.$t("trigger_action_target_reply").toString(),
        value: "Reply",
      },
    ];

    if ([
      ActionsType.KickAction,
      ActionsType.BanAction,
      ActionsType.UnbanAction,
      ActionsType.MuteAction,
      ActionsType.UnmuteAction,
      ActionsType.ChangeReputationIntervalAction,
      ActionsType.ChangeXpIntervalAction,
      ActionsType.ChangePointsIntervalAction,
      ActionsType.WarnAction,
    ].includes(type)) {
      targetOptions.push({
        label: this.$t("trigger_action_target_command_mode").toString(),
        value: "CommandMode",
      });
    }

    return targetOptions;
  }

  getActionExecuteTypeOptions(): Promise<Array<SelectOptionData>> {
    return Promise.resolve(this.actionExecuteType)
  }
}

@Component
export class TriggerInputsSetups extends Vue {
  triggerInputSetupFn(args: FieldData): FieldData {
    if (args.setter === undefined) {
      args.setter = (value: any): void => {
            this.$set(args.model, args.key, value)
      }
    }

    return args
  }

  conditionInputSetupFn(args: FieldData): FieldData {
    return this.triggerInputSetupFn(args)
  }
}
