import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { snakeCase } from "lodash";
import { ConditionsWrapperType } from "../../logic/types/types";
import MessageCountLower from "./conditions/MessageCountLower";
import MessageCountConditionsWrapper from "./MessageCountConditionsWrapper.vue";

const condition: EntityData = {
  'setupView': MessageCountConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.MessageCountConditionsWrapper,
    'currentConditionType': MessageCountLower.structure.type,
    'currentCondition': MessageCountLower.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.MessageCountConditionsWrapper) }`
}

export default condition
