















































































import { ActionSetupView } from '@/components/TriggerSetup/logic/mixins/action-setup-view.mixin'
import { InputSetups } from '@/mixins/input-setups'
import { ChatProperty } from '@/views/chat/chat-properties.vue'
import ActionChance from '@/components/TriggerSetup/components/ActionChance/ActionChance.vue'

import { SelectOption } from 'piramis-base-components/src/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Mixins } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component({
  'components': {
    ActionChance
  }
})
export default class CalculatePropertyTriggerAction extends Mixins<ActionSetupView, UseFields, InputSetups>(ActionSetupView, UseFields, InputSetups) {
  getPropIds(): Promise<Array<SelectOption>> {
    let propertyItemsOptions: Array<SelectOption>

    propertyItemsOptions = this.$store.state.chatState.chat.config.property_items
      .slice()
      .filter((p: ChatProperty) => p.id)
      .map((p: ChatProperty) => {
        return {
          value: p.id,
          label: p.name
        }
      })
    return Promise.resolve(propertyItemsOptions)
  }

  propertyActionTypes(): Promise<Array<SelectOption>> {
    return Promise.resolve([
      {
        label: this.$t('calculate_property_trigger_action_type_set'),
        value: 'Set',
      }, {
        label: this.$t('calculate_property_trigger_action_type_increment'),
        value: 'Increment',
      }
    ])
  }

  propertyTargetTypes(): Promise<Array<SelectOption>> {
    return Promise.resolve([
      {
        label: this.$t('calculate_property_trigger_action_target_user'),
        value: 'TargetUser',
      }, {
        label: this.$t('calculate_property_trigger_action_actor_user'),
        value: 'ActorUser',
      }, {
        label: this.$t('calculate_property_trigger_action_chat_user'),
        value: 'Chat',
      }, {
        label: this.$t('calculate_property_trigger_action_bot_user'),
        value: 'Bot',
      }, {
        label: this.$t('calculate_property_trigger_action_command_mode_user'),
        value: 'CommandMode',
      }
    ])
  }

  valueCalculateTypes(): Promise<Array<SelectOption>> {
    return Promise.resolve([
      {
        label: this.$t('calculate_property_trigger_action_value_type'),
        value: 'Value',
      }, {
        label: this.$t('calculate_property_trigger_action_regex_actor_type'),
        value: 'RegexpActorMessage',
      }, {
        label: this.$t('calculate_property_trigger_action_regex_target_type'),
        value: 'RegexpTargetMessage',
      }
    ])
  }
}
