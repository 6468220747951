



































import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";

import { TagData } from "piramis-base-components/src/components/Tags/types";

import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class ActionUsers extends Mixins<ActionSetupView>(ActionSetupView) {

  getAdmins(): Array<{ label: string, value: string, disabled?: boolean, tags?: Array<TagData> }> {
    const disabledOptions: Array<{label: string, value: string, disabled: boolean, tags:Array<TagData>}> = []

    this.model.users.forEach((user: string) => {
      if (!this.$store.state.chatState.chat.received_administrators.includes(user)) {
        disabledOptions.push({
          value: user,
          label: user,
          disabled: true,
          tags: [ { text: 'Ultimate', color: this.$store.state.themeConfig.config.tariffs.ultimate.color } ]
        })
      }
    })

    return [
      ...this.$store.state.chatState.chat.received_administrators.map((admin: string) => {
        return { label: admin, value: admin }
      }),
      ...disabledOptions
    ]
  }
}
