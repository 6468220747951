


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { ConditionsType } from '../../logic/types/types'
import FirstUserEnterTimeLowerThat from './conditions/FirstUserEnterTimeLowerThat'
import FirstUserEnterTimeMoreThat from './conditions/FirstUserEnterTimeMoreThat'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component
export default class FirstUserEnterTimeConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get firstUserEnterTimeConditionOptions():Array<SelectOption> {
    return conditionsWrappers.FirstUserEnterTimeConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`).toString(),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.FirstUserEnterTimeMoreThat) {
      return FirstUserEnterTimeMoreThat
    } else if (this.conditionData.currentConditionType === ConditionsType.FirstUserEnterTimeLowerThat) {
      return FirstUserEnterTimeLowerThat
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = this.viewByConditionType.structure
      }
    })
  }
}
